import AboutImage from '../../assets/about.jpg'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="sobremi" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About" />
                </div>
            </div>
            <div className="about__right">
                <h2>Sobre mí</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                Soy Software Engineer con experiencia para desarrollar, diseñar y mantener sistemas complejos.
                </p>
                <p>
                Trabajo con equipos de ingeniería a distancia, con gran interés en los desafíos que enfrentan las grandes empresas en el diseño, desarrollo y arquitectura de software.
                </p>
                <p>
                Me gusta explorar nuevas tecnologías, en especial aquellas relacionadas con la nube. He aprendido a trabajar con muchas de ellas a lo largo del tiempo. 
                </p>
                <p>
                Mi vocación es mantener un paso por delante, mirando al futuro para anticiparme a las necesidades y cambios que experimenta la industria.
                </p>
            </div>
        </div>
    </section>
  )
}

export default About