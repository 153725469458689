import { BiData } from "react-icons/bi";
import { RiReactjsLine } from "react-icons/ri";
import { FaServer } from "react-icons/fa";
import { BsFillEyeFill } from "react-icons/bs";

const data = [
  {
    id: 1,
    icon: <RiReactjsLine />,
    title: "Desarrollo de Frontend",
    desc: "Especializado en desarrollo de aplicaciones web con las tecnologías más actuales.",
  },
  {
    id: 2,
    icon: <FaServer />,
    title: "Desarrollo de Backend",
    desc: "Excelentes habilidades para crear aplicaciones del lado del servidor eficientes y de alto rendimiento.",
  },
  {
    id: 3,
    icon: <BsFillEyeFill />,
    title: "Observabilidad Completa",
    desc: "Experiencia práctica con plataformas de observabilidad. Monitoreo de infraestructura.",
  },
  {
    id: 4,
    icon: <BiData />,
    title: "Big Data & Cloud Computing",
    desc: "Experiencia trabajando con plataformas Cloud Computing. Ingesta de datos y procesamiento ETL.",
  },
];

export default data;
